$font-overlock: "Overlock", sans-serif;
$font-Poppins: "Poppins", sans-serif;

body {
  .btn-def {
    padding: 12px 25px;
    border-radius: 0px;
    background-size: 200% auto;
    background-image: linear-gradient(to right,
        #ff9505 0,
        #ff00a3 51%,
        #ff9505 100%);
    color: white;
    font-family: $font-Poppins;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-overlock;
    font-weight: 900;
    font-size: "65px";
  }

  p {
    font-family: $font-Poppins;
  }

  img {
    max-width: 100%;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }
}

.tabss {
  display: flex;
  justify-content: center;
  // font-family: $font-Poppins;
  color: black;
button{
  font-weight: 700;
  font-family: $font-overlock;
 color: #000;
}
}

.section {
  background-color: #f5f5dc;
  color: rgb(0, 0, 0);
  padding-bottom: 50px;
  overflow: hidden;
  // padding-top: 10%;
}

/////////////////
/// ///////(Header)
.container {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;

  .nav {
    box-shadow: none;
  }

  .p-m {
    padding-right: 0%;
    padding-left: 0%;
    padding-top: 15px;
    padding-bottom: 15px;

    .but {
      display: flex;
    }
  }

  .hero {
    padding: 0px 30px;
    padding-top: 230px;
    margin-right: auto;
    font-size: 20px;
    // max-width: 700px;
    // padding-right: 700px;
    font-family: Sock;
    position: relative;

    .Hero-animation {
      position: absolute;
      top: 125px;
      right: -100px;
      width: 60%;
      height: 100%;
    }

    @media only screen and (max-width: 800px) {
      padding-top: 156px;

      .Hero-animation {
        position: static;
        width: 100%;
      }
    }

    .hed {
      font-size: 20px;
      margin-bottom: 25px;
      color: rgb(117, 128, 213);
    }

    .hero-hed {
      font-size: 65px;
      line-height: 75px;
    }

    @media (max-width: 768px) {
      .hero-hed {
        line-height: 40px;
      }
    }

    .le {
      color: rgb(0, 0, 0);
      font-family: $font-Poppins;
    }

    .hero-line {
      line-height: 20px;
      font-size: 18px;
    }
  }

  .pra {
    font-family: $font-Poppins;
    margin-top: 30px;
    line-height: 28px;
  }

  .pra3 {
    margin-left: 180px;
    margin-right: 180px;
    text-align: center;
    font-family: $font-Poppins;
  }

  @media (min-width: 300px) {
    .pra3 {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 55px;
    }
  }

  .section-nk {
    margin-top: 3%;

    // margin-bottom: 3%;
    .css-mhc70k-MuiGrid-root>.MuiGrid-item {
      padding-top: 20px;
      padding-left: 20px;
    }

    .nk-card {
      background-color: #fff;
      border: 1px solid #e5e7eb;
      border-radius: 1rem;
      height: 100%;
      padding: 20px;

      p {
        font-size: 20px;
        font-weight: 600;
        margin-top: 20px;
      }

      .MuiSvgIcon-fontSizeMedium {
        font-size: 55px;
        color: #fff;
        background: #063970;
        padding: 10px;
        border-radius: 30px;
        margin: 0px;
      }

      h5 {
        font-size: 20px;
        line-height: 26px;
        margin-top: 20px;
      }

      @media only screen and (max-width: 800px) {
        h5 {
          font-size: 15px;
        }
      }
    }
  }

  .cards {
    .card {
      margin-bottom: 50px;
      text-align: center;

      h4 {
        font-weight: 900px;
        font-size: 30px;
      }

      .MuiSvgIcon-fontSizeMedium {
        margin-bottom: 5%;
        font-size: 35px;
      }

      h2 {
        margin-bottom: 5%;
      }

      @media (max-width: 768px) {
        h4 {
          font-size: 25px;
        }
      }
    }

    .img {
      width: 100%;
    }

    @media only screen and (max-width: 800px) {
      .img {
        width: 70%;
        padding-left: 112px;
      }
    }

    @media only screen and (max-width: 320px) {
      .img {
        width: 100%;
        padding-left: 0px;
      }
    }
  }

  .pro {
    // margin-bottom: 50px;
    padding-bottom: 20px;
    position: relative;

    .MuiGrid-item {
      // margin-bottom: 65px;
      padding-right: 14px;
    }

    h1 {
      margin-bottom: 25px;
      padding-left: 90px;
      font-size: 2.25rem;
      line-height: 1.5;
    }

    h4 {
      font-size: 30px;
      // padding-left: 90px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 25px;
      // padding-left: 90px;
      font-size: 20px !important;
    }

    .MuiGrid-container {
      margin-bottom: 20px;
      margin-top: 1.875rem;

      .MuiButton-outlined {
        margin-left: 0px;
        font-family: $font-Poppins;
      }
    }

    .img1 {
      width: 80%;
    }

    .img2 {
      // display: flex;
      width: 68%;
      text-align: center;
    }

    .img3 {
      width: 80%;
      // height: 450px;
    }

    .step-counter {
      position: relative;
      padding-left: 90px;
      margin-bottom: 80px;
    }

    .step-counter span.step-number {
      content: "";
      width: 40px;
      height: 40px;
      position: absolute;
      top: 20px;
      left: 20px;
      background: #ff9505;
      color: #fff;
      text-align: center;
      font-size: 18px;
      line-height: 40px;
      font-weight: 600;
      border-radius: 30px;
    }

    .step-image {
      text-align: right;
    }

    @media (max-width: 768px) {
      h4 {
        font-size: 25px;
      }
    }
  }

  .pro:before {
    content: "";
    width: 1px;
    height: 84%;
    position: absolute;
    top: 25px;
    left: 24px;
    background: #000;
  }

  .tabs {
    display: flex;
    justify-content: center;

    font-size: 24px;

    .Build {
      font-size: 24px;
      text-align: center;
      font-weight: bold;
      position: relative;
      padding-left: 50px;
      text-transform: none;
    }

    .build-website:before {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      background: url("images/pencil.png") no-repeat;
      background-size: cover;
      top: 12px;
      left: 10px;
    }

    .custom-analytics:before {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      background: url("images/analysis.png") no-repeat;
      background-size: cover;
      top: 12px;
      left: 10px;
    }

    .performance:before {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      background: url("images/performance.png") no-repeat;
      background-size: cover;
      top: 12px;
      left: 10px;
    }

    .team-management:before {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      background: url("images/team-management.png") no-repeat;
      background-size: cover;
      top: 12px;
      left: 10px;
    }
  }

  .tab-center {
    text-align: center;
    margin-bottom: 35px;
  }

  .how {
    text-align: center;

    .ani {
      width: 80%;
      display: flex;
      align-items: center;
      margin: auto;
      height: 200px;
    }

    .anim {
      width: 53%;
      display: flex;
      align-items: center;
      margin: auto;
      height: 200px;
    }

    h4 {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 1.5em;
    }

    p {
      margin-bottom: 80px;
    }

    .butt {
      justify-content: center;
      margin-bottom: 120px;
    }
  }

  .new {
    .Se {
      font-size: 40px;
    }

    .pe {
      padding-top: 5%;
      font-size: 20px;
    }

    .MuiButton-contained {
      margin-top: 33px;
      margin-left: 12px;
      font-size: 23px;
      margin-bottom: 15px;
    }
  }

  #outlined-basic {
 width: 360px;
    padding-bottom: 27px;
    border-radius: none !important;
  }
 
}

.Remote-sec {
  padding: 70px 0px;

  .rea {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    position: relative;
  }

  .rea::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 2px !important;
    background: linear-gradient(to right, #ff9505 2%, #ff00a3 82%) !important;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
  }

  .pra2 {
    display: flex;
    text-align: center;
    margin-left: 170px;
    margin-right: 170px;
    margin-bottom: 3%;
    font-family: $font-Poppins;
  }

  @media (min-width: 300px) {
    .pra2 {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .grid-layout-main {

    // margin-bottom: 10%;
    .MuiGrid-container {
      justify-content: center !important;

      .grid-item-box:hover {
        box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
      }
    }

    .grid-item-box {
      transition: all 0.5s ease;
      border: 1px solid #cfe5f7;
      padding: 25px;
      margin-bottom: 20px;
      border-radius: 10px;
      background-color: #ffffff;
      width: 100%;
      text-align: center;
    }

    h4 {
      font-size: 15px;
      margin-top: 10px;
    }
  }
}

.Remote-devloper {
  padding: 70px 0px;

  .cards-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.process {
  padding: 70px 0px;
  background-color: #f9f9f9;
  .btn-cen{
    margin-left: 120px;
    margin-top: -340px
  }
}

.adv {
  padding: 70px 0px;
}

.why {
  padding: 70px 0px;
  // background: #f9f9f9;
}

.bg3 {
  background-color: #f9f9f9;

  padding: 70px 0px;
}

.back {
  background-color: #f9f9f9;
  padding-bottom: 80px;
  padding-top: 60px;

  .new {
    background: #e6efff;
    padding: 100px 60px;
    position: relative;
    border-radius: 30px;
  }

  img {
    // height: 100%;
    position: absolute;
    // top: 0px;
    right: 80px;
    bottom: 0px;
  }

  @media only screen and (max-width: 800px) {
    img {
      position: static;
    }
  }

  @media only screen and (max-width: 1080px) {
    img {
      position: static;
      padding-bottom: 0px;
    }

    .new {
      padding-bottom: 0px;
    }
  }
}

.heading {
  text-align: center;
  width: 800px;
  margin: auto;

  .rea {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    position: relative;
  }

  .rea::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 2px !important;
    background: linear-gradient(to right, #ff9505 2%, #ff00a3 82%) !important;
    left: 0;
    right: 0;
    bottom: -5px;
    margin: 0 auto;
  }

  .head {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 50px;

    @media (max-width: 768px) {
      font-size: 35px;
    }
  }
}

.footer {
  padding-top: 60px;

  hr{
    padding-top: 25px;
    margin: 0 !important;
  }
}

h3.foot-last {
  font-size: 18px;
}

@media only screen and (max-width: 800px) {
  h3.foot-last {
    padding-top: 15px;
  }
}

.foot-img {
  width: 22%;
  // margin-top: 40%;
  margin-bottom: 25px;
}

.foot-p {
  font-size: 16px;
  margin-bottom: 25px;
  width: 70%;
}

.fot-li {
  font-size: 30px;
  text-align: right;
  margin-right: 20px;
}

.icon-color {
  color: rgba(17, 101, 210, 0.897);
  display: flex;
}

.foot-link {
  display: flex;
  flex-direction: column;
  font-family: $font-Poppins;
  margin-top: 35px;

  @media only screen and (max-width: 800px) {
    .foot-link {
      margin-top: 20px;
    }
  }
}

.top-m {
  margin-top: 55px;
}

.copy {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 20px;

  .di {
    font-family: $font-Poppins;
  }
}

@media only screen and (max-width: 800px) {
  .heading {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .tabs * {
    flex-direction: column;
  }
}

@media only screen and (max-width: 800px) {
  .copy {
    display: flex;

    flex-direction: column;

    .di {
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .container .pro .step-counter span.step-number {
    top: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1080px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fot-li {
  color: #000;
}

@media only screen and (max-width: 800px) {
  .section {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 800px) {
  .back .new {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}

.Mo {
  // display: flex;
  text-decoration: none;

  hr {
    position: absolute;
    top: 45px;
    width: 125px;
  }
}

.Mo-link {
  .lin {
    text-decoration: none;
    color: #000;
    font-family: $font-Poppins;
    font-weight: 300;
  }

  h3 {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

.dr-icon {
  position: absolute;
  top: 45%;
  left: 77%;
  color: #837c7c;
}

.pop-form {
  fieldset {
    padding: 20px 16px;
    border-radius: 30px;
    // background-color: #d1f3ff;
    color: black;
  }

  .customPhoneInput {
    input {
      width: 100%;
      margin-bottom: 19px;
      border: 1px solid black;
      border-radius: 30px;
      /* Change 'black' to your preferred color */
      padding: 15px;
      // background-color: #d1f3ff;
      color: black;
      ::placeholder {
        color: black;
      }
    }
  }

  .PhoneInput {

    position: relative;

    .PhoneInputCountry {
      position: absolute;
      top: 17px;
      left: 15px;
      z-index: 99;
    }

    .PhoneInputInput {
      padding-left: 70px;
    }
  }
  .pop-btn{
    width: 50%;
    border-radius: 30px;
   
  }
}
.ile{
 text-decoration: underline;
 font-family: $font-overlock;
}
.letheader{
 
  justify-content: space-between !important;
}
@media (max-width: 786px) {
  .container{
  #outlined-basic{
    width:100%
  }}
}