.about-main {
  padding-top: 180px;
  margin-right: auto;
  font-size: 20px;

  // font-family: $font-arimo;
  position: relative;
  border-radius: 30px;

  padding-bottom: 50px;
  .About-container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    .about-center {
      display: flex;
      align-items: center;
    }
    .hed {
      font-size: 20px;
      margin-bottom: 25px;
      color: rgb(117, 128, 213);
    }
    .about-hed {
      font-size: 60px;
      line-height: 75px;
    }
    .pra {
      margin-top: 30px;
      line-height: 28px;
      margin-bottom: 30px;
    }
    .img1 {
      padding-top: -115px;
      // padding-bottom: 70px;
    }
    .about-icon {
      background-color: rgb(125, 125, 176);
      padding: 20px 20px;
      display: flex;
      justify-content: space-around;
      border-radius: 30px;
      color: white;
      margin-bottom: 30px;
      margin-top: 70px;
      .i1 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
      }

      .css-i4bv87-MuiSvgIcon-root {
        // width: 3em;
        font-size: 40px;
        color: white;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .About-container {
      margin-left: 10px;
      h1 {
        font-size: 30px;
      }
      // margin-right: 10px;
    }
    .about-icon {
      margin-right: 15px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .About-container {
      max-width: 1300px;
      width: 100%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.core-bg {
  background-color:#f9f9f9;;
  padding-top: 40px;
  padding-bottom: 40px;
  .core-container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    .re {
      font-size: 14px;
      background-size: 200% auto;
      background-image: linear-gradient(
        to right,
        #ff9505 0,
        #ff00a3 51%,
        #ff9505 100%
      );
      color: #fff;
      padding: 8px 20px;
      width: fit-content;
      margin: 0 auto 12px;
    }
    p {
      text-align: center;
      padding-bottom: 20px;
      padding-right: 11px;
    }
    .core-head {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 45px;
      text-align: center;
    }
    .core-card {
      .MuiGrid-container {
        display: flex;
        // flex-wrap: nowrap;
      }
      .grid-item-box:hover {
        box-shadow: 2px 2px 32px rgba(23, 22, 22, 0.2);
      }
      .grid-item-box {
        transition: all 0.5s ease;
        border: 1px solid #cfe5f7;
        padding-top: 60px;
        padding-right: 20px;
       height: 100%;
        padding-left: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .core-center {
          display: flex;
          text-align: center;
          justify-content: center;
          padding-bottom: 15px;
        }
        .pra-center {
          display: flex;
          text-align: center;
          justify-content: center;
          padding-bottom: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .core-container {
      margin-left: 10px;
      // margin-right: 10px;
    }
    .core-card {
      margin-right: 15px;
    }
  }
  @media only screen and (max-width: 1440px) {
    .core-container {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }}
.choose-main {
  background-image: url("./img/about-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 140px 0px 120px 0px;
  background-color: #f9f9f9;;
  .choose-container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 50px;

    .img2 {
      margin-top: 105px;
      margin-left: 25px;
    }
    .choose-head {
      margin-top: 100px;
      color: white;

      h2 {
        font-size: 40px;
        margin-bottom: 20px;
      }
    }
    .all-cho {
      display: flex;

      .all-cho-inner {
        display: flex;
      }
    }
    .cho-icon {
      // display: flex;
      width: 80px;
      margin-top: 20px;
    }
    .icon-head {
      margin-left: 25px;
      margin-top: 20px;
      h3 {
        font-size: 28px;
        padding-bottom: 15px;
      }
      p {
        line-height: 1.9rem;
      }
    }
    .choose-card {
      display: flex;
      margin-top: 10px;

      .all-cho-inne {
        display: flex;
        padding-left: 15px;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    .choose-container {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
@media (max-width: 768px) {
  .all-cho {
    display: flex;
    flex-direction: column;
  }
  .choose-card {
    display: flex;
    flex-direction: column;
  }
  .choose-main {
    .choose-container {
      .icon-head {
        h3 {
          font-size: 20px;
        }
      }
    }
  }
}
.about-remote {
  padding-bottom: 120px;
}
// .choose-main {
//   background-image: url("./img/about-bg.png");
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: cover;
//   padding: 140px 0px 120px 0px;

//   .choose-container {
//     max-width: 1300px;
//     width: 100%;
//     margin: 0 auto;
//     padding-bottom: 50px;
//     display: flex;
//     flex-wrap: wrap;

//     .img2 {
//       width: 100%;
//       max-width: 100%;
//       height: auto;
//       margin-top: 20px;
//       order: 1; // Move the image to the left on larger screens
//     }

//     .choose-head {
//       flex: 1;
//       color: white;
//       margin-top: 20px;
//       order: 2; // Move the text and boxes to the right on larger screens

//       h1 {
//         font-size: 40px;
//         margin-bottom: 20px;
//       }

//       .all-cho,
//       .choose-card {
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: center;

//         .all-cho-inne,
//         .all-cho-inne {
//           flex: 1;
//           display: flex;
//           flex-direction: column;
//           margin: 0 10px 20px;
//           max-width: 300px;
//         }

//         .cho-icon {
//           width: 80px;
//           margin: 0 auto;
//         }

//         .icon-head {
//           text-align: center;
//           margin-top: 20px;

//           h3 {
//             font-size: 28px;
//             padding-bottom: 15px;
//           }

//           p {
//             line-height: 1.9rem;
//           }
//         }
// }
// }
// }

//   @media only screen and (min-width: 768px) {
//     .choose-container {
//       .img2 {
//         width: 33%;
//         order: 0; // Reset order for larger screens
//       }

//       .choose-head {
//         order: 0; // Reset order for larger screens
//       }
//     }
//   }
// }
