.cl{
  background-color:white;
}
.degree-card {
  padding-bottom: 25px;
  .grid-item-box {
    .tech {
      font-size: 24px !important;
    }
  }
}
.degree-card {
  .techd {
    .techa {
      display: flex !important;
      justify-content: flex-start;
      font-size: 24px !important;
      text-align: left !important;

      .dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #ffba44;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        margin-right: 8px;
      }
    }
  }
  .left {
    text-align: left !important;
    //   padding-bottom: 15px !important;
  }
  
}

.techd {
  transition: all 0.5s ease;
  border: 1px solid #cfe5f7;
  padding-top: 30px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fafaf8;
  width: 100%;
  height: 90%;
}
.techd:hover {
  -webkit-box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 2px 15px 1px rgba(0, 0, 0, 0.08) !important;
  -webkit-transform: translateY(-10px) !important;
  -ms-transform: translateY(-10px) !important;
  -o-transform: translateY(-10px) !important;
  transform: translateY(-10px) !important;
  background-color: white;
}
.teche-main {
  padding-top: 40px;
  padding-bottom: 120px;
  .teche-container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    // text-align: center;

    .teche-head {
      h2 {
        padding-top: 15px;
        text-align: center;
        font-size: 48px;
      }
    }
    .main-car {
      display: flex;
      justify-content: space-evenly;
      .main-c {
        padding-bottom: 30px;
        padding-left: 45px;
      }
      .year {
        margin-top: 70px;

        font-size: 60px;
        padding-left: 40px;
      }
      .hp {
        // margin-top: 60px;
        font-size: 40px;
        padding-left: 15px;
        p {
          font-size: 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 1468px) {
    .teche-container {
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-car {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 800px) {
  body {
    h1 {
      font-size: 30px !important;
    }
    h3 {
      font-size: 30px;
    }
  }
}
