.Contact-main {
  padding-bottom: 200px;
  .Contact-container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    padding-top: 250px;
    .hed{
      font-size: 26px;
      color: rgb(117, 128, 213);
      ;
    }
    .co-head {
      padding-top: 20px;
      margin-right: auto;
      font-size: 32px;
      margin-bottom: 30px;
    }
    .co-pra {
      margin-bottom: 30px;
    }
    .co-h {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-bottom: 20px;
      p{
      font-weight: 300;
      }
      .call-icon {
        width: 2rem;
        height: 1em;
        margin-left: 8px;
        margin-right: 18px;
      }
    }
    .con-form {
      width: 50%;
    }
    .co-form {
      // padding-top: 200px;

      margin-left: 20px;
      fieldset {
        padding: 20px 16px;
        border-radius: 30px;
      }
    }

    .PhoneInputInput {
      flex: 1.81;
      min-width: 0;
      padding: 16.5px;

      border-radius: 30px;
      background-color: #d1f3ff;
      border-color: #0000003b;
      border-width: 1px;
      display: flex;
      position: relative;
      margin: 0px 0px 16px;
      padding-left: 70px;
    }
    .PhoneInputInput:hover {
      border-color: #000000de;
    }
    .PhoneInputInput:focus {
      border-color: #1976d2 !important;
      border-width: 2px;
    }
    .PhoneInput {
      position: relative;
      .PhoneInputCountry {
        // padding-left: 19px;
        position: absolute;
        top: 17px;
        left: 15px;
        z-index: 99;
      }
    }
    .form-input {
      border-radius: 30px;
      background-color: #d1f3ff;
    }

    .MuiButton-containedPrimary {
      border-radius: 30px;
      margin-left: 10px;
      width: 40%;
    }
  }

  @media only screen and (max-width: 1440px) {
    .Contact-container {
      max-width: 1300px;
      width: 100%;
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
    }
    .co-h{
      p{
        font-size: 17px;
      }

      
    }
  }
  
}